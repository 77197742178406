import { ReactNode } from "react";
import { IconType } from "react-icons";

interface Props {
  children: ReactNode;
  Icon: IconType;
}

const InputGroup: React.FC<Props> = ({ children, Icon }) => {
  return (
    <div className="input-group flex-nowrap">
      <span className="input-group-text">
        <Icon />
      </span>
      {children}
    </div>
  );
};

export default InputGroup;

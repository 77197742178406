import PaymentStatus from "../enums/payment-status";

export function ConvertPaymentStatusToString(status: PaymentStatus) {
  switch (status) {
    case PaymentStatus.Paid:
      return "Pago";
    case PaymentStatus.Overdue:
      return "Vencido";
    case PaymentStatus.Trial:
      return "Teste";
    case PaymentStatus.Pending:
      return "Pendente";

    default:
      return "Status desconhecido";
  }
}

import { AxiosError, isAxiosError } from "axios";
import toast from "react-hot-toast";
import ServiceResult from "../interfaces/service-result";

export default function apiErrorHandler(error: Error | AxiosError): void {
  if (
    isAxiosError(error) &&
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500
  ) {
    //Se retornar erro de autenticação...
    if (error.response.status === 401) {
      //Possui token salvo? significa que não está na tela de login ou cadastro!
      const auth = sessionStorage.getItem("auth");

      //exibe uma mensagem e manda para tela de login novamente!
      if (auth) {
        toast.error("Sua sessão expirou, entre novamente");
        sessionStorage.clear();
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
        return;
      }
    }

    const result: ServiceResult = error.response.data;

    if (!result.messages) {
      toast.error("Ocorreu um erro inesperado. Tente novamente mais tarde.");
      return;
    }

    toast.error(
      result.messages.map((m: any) => "➡️ " + m.message).join("\n\n"),
    );

    return;
  }

  toast.error("Ocorreu um erro inesperado. Tente novamente mais tarde.");
}

export function getApiErrorMessage(error: Error | AxiosError): string {
  if (
    isAxiosError(error) &&
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500
  ) {
    const result: ServiceResult = error.response.data;

    if (!result.messages) {
      return "Ocorreu um erro inesperado. Tente novamente mais tarde.";
    }

    return result.messages.map((m: any) => "➡️ " + m.message).join("\n\n");
  }

  return "Ocorreu um erro inesperado. Tente novamente mais tarde.";
}

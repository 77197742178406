import { colors } from "../../style/colors";
import "../layouts/background.css";

interface Props {
  children: React.ReactNode;
  renderPosition: "left" | "right";
  image: string;
  title: string;
  description?: string | null;
}

export default function CleanLayout({
  children,
  renderPosition,
  image,
  title,
  description,
}: Props) {
  return (
    <div
      className="container-fluid p-0"
      style={{ overflowX: "hidden", height: "100vh" }}
    >
      <div
        className="d-md-none py-4 text-white d-flex justify-content-center align-items-center"
        style={{ background: colors.gradient.primary }}
      >
        <p className="h2 text-center">{title}</p>
      </div>

      <div className="row g-0">
        {renderPosition === "left" && (
          <div className="col-md-6 col-xl-4 p-4 d-flex flex-column justify-content-center align-items-center">
            {children}
          </div>
        )}

        <div
          className="d-none col-md-6 col-xl-8 text-white d-md-flex justify-content-center align-items-center"
          style={{ background: colors.gradient.primary }}
        >
          <div className="row min-vh-100 p-4 d-flex justify-content-center align-items-center">
            <div className="col-xl-12 d-flex flex-column justify-content-center align-items-center">
              <p className="text-center text-400-white-46">{title}</p>
              <div className="col-xl-6 d-flex flex-column justify-content-center align-items-center">
                {description && (
                  <p className="text-center text-400-white-16">{description}</p>
                )}
                <img src={image} alt="background" width={400} />
              </div>
            </div>
          </div>
        </div>

        {renderPosition === "right" && (
          <div className="col-md-6 col-xl-4 p-4 d-flex flex-column justify-content-center align-items-center">
            {children}
          </div>
        )}
      </div>
    </div>
  );
}

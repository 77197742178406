import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import MainLayout from "../../components/layouts/main";
import Button from "../../components/button";
import { MdArrowBack } from "react-icons/md"; // Importando o ícone de voltar
import { useNavigate, useParams } from "react-router-dom";
import CheckoutModel from "../../interfaces/models/checkout.model";
import api from "../../services/api-client";
import apiErrorHandler from "../../services/api-error-handler";
import { Rules } from "./components/rules";
import PaymentStatus from "../../enums/payment-status";

export default function Checkout() {
  const navigate = useNavigate();
  const { instanceId } = useParams();
  const [timeLeft, setTimeLeft] = useState(60 * 5);
  const [showNewQRCodeButton, setShowNewQRCodeButton] = useState(false);
  const [checkout, setCheckout] = useState<CheckoutModel | null>(null);
  const [status, setStatus] = useState<PaymentStatus>({} as PaymentStatus);
  const [loading, setLoading] = useState(false);

  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const remainingSeconds = timeLeft % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  async function postCheckout() {
    setLoading(true);
    api
      .post(`/connections/whatsapp/instances/${instanceId}/checkout`)
      .then(({ data }) => {
        setCheckout(data.result);
        setTimeLeft(60 * 5);
        setShowNewQRCodeButton(false);
      })
      .catch((error) => {
        apiErrorHandler(error);
        navigate(-1);
      })
      .finally(() => setLoading(false));
  }

  async function getStatusTransaction() {
    api
      .get(
        `/connections/whatsapp/instances/${instanceId}/checkout/${checkout?.transactionId}/status`
      )
      .then(({ data }) => {
        setStatus(data.result);
      })
      .catch(apiErrorHandler);
  }

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setShowNewQRCodeButton(true);
    }
  }, [timeLeft]);

  const handleGenerateNewQRCode = () => {
    postCheckout();
  };

  useEffect(() => {
    postCheckout();
  }, []);

  useEffect(() => {
    if (checkout?.qrCode) {
      getStatusTransaction();

      const interval = setInterval(() => {
        getStatusTransaction();
      }, 5e3);

      return () => clearInterval(interval);
    }
  }, [checkout?.qrCode]);

  return (
    <MainLayout>
      <div
        className="d-flex align-items-center cursor-pointer mb-4"
        onClick={() => navigate(-1)}
      >
        <MdArrowBack size={20} />
        <span className="semibold-16 mx-2">Voltar</span>
      </div>

      {loading ? (
        <p>Carregando...</p>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="bg-white shadow rounded p-4"
            style={{
              width: "640px",
              borderRadius: "8px",
            }}
          >
            <div className="text-center">
              <div className="" style={{ lineHeight: "15px" }}>
                <p className="bold-20">Renove sua conexão</p>
                <p className="text-500-darkest-16">{checkout?.name}</p>
              </div>
              <hr />
              <div className="bold-16" style={{ lineHeight: "5px" }}>
                <p className="">Valor da compra:</p>
                <p style={{ color: "rgb(43, 101, 214)" }}>
                  {checkout ? `R$ ${checkout.amount.toFixed(2)}` : "R$ 0,00"}
                </p>
              </div>
            </div>
            {timeLeft > 0 && checkout?.qrCode && (
              <QRCode
                value={checkout.qrCode}
                className="d-block"
                style={{ margin: "10px auto" }}
              />
            )}
            <div
              className="text-center text-500-darkest-12"
              style={{
                fontFamily: "Ubuntu, sans-serif",
              }}
            >
              <Rules />

              {timeLeft > 0 && checkout?.qrCode && (
                <Button
                  text="Copiar código pix"
                  disabled={timeLeft === 0}
                  onClick={() => {
                    navigator.clipboard.writeText(checkout?.qrCode ?? "");
                  }}
                />
              )}

              <div className="d-flex justify-content-center align-items-center mt-3">
                <p
                  className="text-500-darkest-16"
                  style={{ marginRight: "10px" }}
                >
                  Tempo restante: <strong>{formatTime()}</strong>
                </p>
              </div>
              {showNewQRCodeButton && (
                <div className="">
                  <Button
                    text="Gerar novo QR Code"
                    width="200px"
                    onClick={handleGenerateNewQRCode}
                    style={{ height: "30px" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
}

import MainLayout from "../../components/layouts/main";
import img from "../../images/construcao.png";

export default function Construction() {
  return (
    <MainLayout>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div>
          <img
            src={img}
            className="img-fluid"
            style={{ maxWidth: "500px" }}
            alt="Em construção"
          />
        </div>
      </div>
    </MainLayout>
  );
}

import clsx from "clsx";
import { useEffect, useState } from "react";

import ServiceResult from "../../interfaces/service-result";
import api from "../../services/api-client";
import apiErrorHandler from "../../services/api-error-handler";
import { useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";

import MeModel from "../../interfaces/models/me.model";
import { useProfileStore } from "../../stores/account.store";

export default function Header() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const { profileData, setProfileData } = useProfileStore();

  async function getProfileData(): Promise<void> {
    setLoading(true);
    api
      .get<ServiceResult<MeModel>>("/me")
      .then(({ data }) => {
        setProfileData(data.result as MeModel);
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getProfileData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-end p-3 header">
      <div
        className="d-flex align-items-center cursor-pointer"
        onClick={() => {
          sessionStorage.clear();
          navigate("/login");
        }}
      >
        <MdLogout size={20} />
        <span className="semibold-16 mx-2">Sair</span>
      </div>
    </div>
  );
}

import MainLayout from "../../components/layouts/main";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";
import { CardsInstances } from "../../components/card-instances/cards-instances";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
);

export default function Dashboard() {
  const data = {
    labels: ["26/07/2024", "27/07/2024", "28/07/2024", "29/07/2024"],
    datasets: [
      {
        label: "Qtd.recebida",
        data: [9, 18, 27, 36],
        borderColor: "#DAA520",
        backgroundColor: "#DAA520",
      },
      {
        label: "Qtd. enviada",
        data: [5, 15, 25, 35],
        borderColor: "#00CED1",
        backgroundColor: "#00CED1",
        tension: 0.1, // Opcional: faz a linha ser suave
      },
    ],
  };

  return (
    <MainLayout>
      <h1 className="mb-3">Dashboard</h1>

      <div className="d-flex justify-content-end">
        <p className="mb-0">Última atualização 26/07/2024 16:49:50</p>
      </div>

      {/* Cards instâncias */}
      <CardsInstances />

      {/* Input + cards => instâncias */}
      <div className="row">
        <div className="col-xl-8">
          <div className="bg-white shadow px-3 py-4 rounded-2 h-100">
            <div className="row">
              <div className="col-12 col-xxl-7">
                <p className="fw-semibold mb-0">Instância web</p>
                <select className="form-select input-outlined w-100 mt-2">
                  <option value="todos">Todos</option>
                </select>
              </div>
              <div className="col-12 col-xxl-5 mt-4 mt-xxl-0">
                <p className="fw-semibold mb-0">Período</p>
                <div className="d-flex align-items-center w-100 mt-2">
                  <button className="btn btn-primary rounded-pill border-0 p-2 w-100">
                    Este mês
                  </button>
                  <button className="btn btn-primary rounded-pill border-0 p-2 w-100 mx-2">
                    Mês passado
                  </button>
                  <button className="btn btn-primary rounded-pill border-0 p-2 w-100">
                    Ontem
                  </button>
                  <button className="btn btn-primary rounded-pill border-0 p-2 w-100 ms-2">
                    Hoje
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 mt-lg-4 mt-xl-0">
          <div className="row">
            <div className="col-xl-12 col-xxl-6">
              <div
                className="rounded-2 d-flex flex-column align-items-center justify-content-center text-white fw-bold"
                style={{ height: "120px", background: "#00CED1" }}
              >
                <span>
                  <FiChevronsRight size={16} /> Total enviado
                </span>
                <span>56</span>
              </div>
            </div>

            <div className="col-xl-12 col-xxl-6 mt-3 mt-xxl-0">
              <div
                className="rounded-2 d-flex flex-column align-items-center justify-content-center text-white fw-bold"
                style={{ height: "120px", background: "#DAA520" }}
              >
                <span>
                  <FiChevronsLeft /> Total recebido
                </span>
                <span>54</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Gráfico + mensagens */}
      <div className="row mt-4">
        <div className="col-xl-8">
          <div className="bg-white shadow px-3 py-4 rounded-2 h-100">
            <p className="fw-semibold mb-0">
              Total recebido/enviado no último mês
            </p>
            <Line data={data} className="mt-2" />
          </div>
        </div>

        <div className="col-xl-4 mt-lg-4 mt-xl-0">
          <div className="bg-white shadow px-3 py-4 rounded-2 h-100">
            <div className="d-flex align-items-center justify-content-between">
              <p className="fw-semibold mb-0">Mensagens Enviadas</p>

              <div className="d-flex align-items-center">
                <button className="btn btn-primary rounded-pill border-0 mx-2">
                  Enviadas
                </button>
                <button className="btn btn-secondary rounded-pill border-0">
                  Recebidas
                </button>
              </div>
            </div>

            <div
              className="d-flex align-items-center justify-content-between mt-3"
              style={{ fontSize: "14px" }}
            >
              <p>Áudios</p>
              <p className="text-success fw-bold">0</p>
            </div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ fontSize: "14px" }}
            >
              <p>Vídeos</p>
              <p className="text-success fw-bold">0</p>
            </div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ fontSize: "14px" }}
            >
              <p>Botões</p>
              <p className="text-success fw-bold">0</p>
            </div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ fontSize: "14px" }}
            >
              <p>Contatos</p>
              <p className="text-success fw-bold">0</p>
            </div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ fontSize: "14px" }}
            >
              <p>Documentos</p>
              <p className="text-success fw-bold">0</p>
            </div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ fontSize: "14px" }}
            >
              <p>Imagens</p>
              <p className="text-success fw-bold">0</p>
            </div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ fontSize: "14px" }}
            >
              <p>Links</p>
              <p className="text-success fw-bold">0</p>
            </div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ fontSize: "14px" }}
            >
              <p>Localizações</p>
              <p className="text-success fw-bold">0</p>
            </div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ fontSize: "14px" }}
            >
              <p>Opções</p>
              <p className="text-success fw-bold">0</p>
            </div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ fontSize: "14px" }}
            >
              <p>Stickers</p>
              <p className="text-success fw-bold">0</p>
            </div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ fontSize: "14px" }}
            >
              <p className="mb-0">Textos</p>
              <p className="text-success fw-bold mb-0">0</p>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

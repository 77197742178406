export function Rules() {
  return (
    <div style={{ color: "#aaaaaa" }}>
      <strong style={{ color: "rgb(43, 101, 214)", fontSize: "16px" }}>
        Como realizar o pagamento utilizando o aplicativo do seu banco:
      </strong>
      <ul style={{ listStyle: "none", padding: 0 }}>
        <li>1. Abra o aplicativo do seu banco no seu celular.</li>
        <li style={{ marginBottom: "10px" }}>
          2. Encontre a opção para pagamento via Pix (geralmente localizada na
          tela inicial ou no menu de pagamentos).
        </li>
        <li style={{ marginBottom: "10px" }}>
          3. Selecione a opção de "Ler QR Code" ou "Pagar com QR Code".
        </li>
        <li style={{ marginBottom: "10px" }}>
          4. Aponte a câmera do seu celular para o QR Code exibido nesta tela.
        </li>
        <li style={{ marginBottom: "10px" }}>
          5. Verifique as informações de pagamento, incluindo o valor total.
        </li>
        <li style={{ marginBottom: "10px" }}>
          6. Confirme o pagamento no aplicativo do seu banco.
        </li>
        <li style={{ marginBottom: "10px" }}>
          7. Após a confirmação, você receberá uma notificação de que o
          pagamento foi realizado com sucesso.
        </li>
      </ul>
      <p style={{ fontSize: "12px" }}>
        Se tiver algum problema, entre em contato com o nosso suporte.
      </p>
    </div>
  );
}

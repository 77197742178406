import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import api from "../../../services/api-client";
import apiErrorHandler from "../../../services/api-error-handler";
import Input from "../../../components/inputs/input";
import RegisterPostArgs from "../../../interfaces/args/register-post.args";
import ServiceResult from "../../../interfaces/service-result";
import CleanLayout from "../../../components/layouts/clean";
import { colors } from "../../../style/colors";
import Button from "../../../components/button";

export const RegisterForm = () => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<RegisterPostArgs>({
    defaultValues: {
      fullName: "",
      email: "",
      whatsApp: "",
      password: "",
      passwordConfirm: "",
    },
  });

  const [loading, setLoading] = useState<boolean>(false);

  async function handleRegister(data: RegisterPostArgs): Promise<void> {
    setLoading(true);

    const args: RegisterPostArgs = {
      fullName: data.fullName,
      email: data.email,
      whatsApp: data.whatsApp,
      password: data.password,
      passwordConfirm: data.passwordConfirm,
    };

    api
      .post<ServiceResult>("/register", args)
      .then(() => {
        // setSearchParams({ confirmation: "true" });
        navigate("/login");
      })
      .catch(apiErrorHandler)
      .finally(() => setLoading(false));
  }

  return (
    <CleanLayout
      renderPosition="right"
      title="Bem-vindo ao IATools"
      description="Estamos felizes em ter você aqui! Crie sua conta para começar a
                aproveitar todas as vantagens e funcionalidades da nossa
                plataforma."
      image="/images/register-icon.png"
    >
      <h1>Criar conta</h1>
      <form
        className="text-500-darkest-16 w-100 mt-md-5 d-flex flex-column justify-content-center"
        onSubmit={handleSubmit(handleRegister)}
      >
        <p className="text-400-black-18 m-0 mb-1">Nome completo</p>
        <div className="form-group mb-4">
          <Input
            control={control}
            errors={errors}
            validation={{ required: "Nome completo é obrigatório" }}
            placeholder="Informe seu nome completo"
            height="40px"
            name="fullName"
          />
        </div>

        <div className="form-group mb-4">
          <p className="text-400-black-18 m-0 mb-2">E-mail</p>
          <Input
            control={control}
            errors={errors}
            validation={{ required: "E-mail é obrigatório" }}
            placeholder="example@email.com"
            height="40px"
            name="email"
          />
        </div>

        <div className="form-group mb-4">
          <p className="text-400-black-18 m-0 mb-2">Telefone</p>
          <Input
            control={control}
            name="whatsApp"
            mask="(99) 99999-9999"
            height="40px"
            errors={errors}
            validation={{ required: "Telefone é obrigatório" }}
          />
        </div>

        <div className="form-group mb-4">
          <p className="text-400-black-18 m-0 mb-2">Senha</p>
          <Input
            control={control}
            errors={errors}
            validation={{ required: "Senha é obrigatória" }}
            placeholder="senha"
            height="40px"
            type="password"
            name="password"
          />
        </div>

        <div className="form-group mb-4">
          <p className="text-400-black-18 m-0 mb-2">Confirme sua senha</p>
          <Input
            control={control}
            errors={errors}
            validation={{
              required: "Confirmação de senha é obrigatória",
              validate: (value) => {
                return value === watch("password") || "As senhas não coincidem";
              },
            }}
            placeholder="confirme sua senha"
            height="40px"
            type="password"
            name="passwordConfirm"
          />
        </div>

        <div className="mt-4">
          <Button
            text={loading ? "Cadastrando..." : "Cadastrar"}
            color={colors.gradient.secondary}
            disabled={loading}
          />
        </div>

        <div className="mt-3 text-center">
          <span>Já possui uma conta?</span>
          <Link
            to="/login"
            className="text-500-darkest-16 ms-2"
            style={{ color: colors.secondary }}
          >
            Entrar
          </Link>
        </div>
      </form>
    </CleanLayout>
  );
};

import { Toaster } from "react-hot-toast";
import AppRouter from "./routes";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";

export default function App() {
  return (
    <>
      <Toaster position="top-right" />
      <AppRouter />
    </>
  );
}

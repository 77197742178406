enum GrantType {
  /// <summary>
  /// Third part applications credentials
  /// </summary>
  ClientCredentials = "ClientCredentials",
  /// <summary>
  /// Username and Password
  /// </summary>
  Password = "Password",
  /// <summary>
  /// OTP authentication
  /// </summary>
  Passwordless = "Passwordless",
  /// <summary>
  /// Long lifetime authentication token
  /// </summary>
  RefreshToken = "RefreshToken",
  /// <summary>
  /// Two factor authentication
  /// </summary>
  TwoFactorAuthentication = "TwoFactorAuthentication",
  TenantSecretKey = "TenantSecretKey",
  WorkspaceSecretKey = "WorkspaceSecretKey",
}

export default GrantType;

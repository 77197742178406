import { FiHardDrive } from "react-icons/fi";
import MainLayout from "../../../../components/layouts/main";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ConnectionsWhatsAppInstancesPostArgs from "../../../../interfaces/args/connections-whatsapp-instances.post.args";
import { useForm } from "react-hook-form";
import api from "../../../../services/api-client";
import ServiceResult from "../../../../interfaces/service-result";
import apiErrorHandler from "../../../../services/api-error-handler";
import Input from "../../../../components/inputs/input";
import InputGroup from "../../../../components/inputs/input-group";
import { LuClipboardEdit } from "react-icons/lu";

export default function NewInstance() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<ConnectionsWhatsAppInstancesPostArgs>({
    defaultValues: {
      name: "",
      description: "",
      rejectCalls: true,
      rejectCallsMessage: "",
      alwaysOnLine: true,
      readStatus: true,
      readMessages: true,
      subscriptionId: null,
    },
  });

  const watchRejectCalls = watch("rejectCalls");

  async function onSubmit(
    data: ConnectionsWhatsAppInstancesPostArgs
  ): Promise<void> {
    setLoading(true);

    let args: ConnectionsWhatsAppInstancesPostArgs = {
      subscriptionId: data.subscriptionId ?? null,
      description: data.description ?? "",
      name: data.name,
      rejectCalls: data.rejectCalls,
      rejectCallsMessage: data.rejectCallsMessage ?? "",
      alwaysOnLine: data.alwaysOnLine,
      readStatus: data.readStatus,
      readMessages: data.readMessages,
    };

    api
      .post<ServiceResult<ConnectionsWhatsAppInstancesPostArgs>>(
        "/connections/whatsapp/instances",
        args
      )
      .then(() => {
        navigate("/whatsapp/instances");
        toast.success("Instância criada com sucesso!");
      })
      .catch(apiErrorHandler)
      .finally(() => setLoading(false));
  }

  return (
    <MainLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white shadow p-4">
          <p className="mb-0 bold-20">1. Dados da instância web</p>
          <div className="row mt-3">
            <div className="col-lg-4">
              <div className="d-flex">
                <label className="form-label semibold-14 text-secondary">
                  Nome da instância
                </label>
              </div>
              <InputGroup Icon={FiHardDrive}>
                <Input
                  control={control}
                  errors={errors}
                  validation={{ required: "Nome da instância é obrigatório" }}
                  name="name"
                  type="text"
                  id="name"
                  placeholder="Nome da instância"
                />
              </InputGroup>
            </div>

            <div className="col-lg-8 mt-3 mt-lg-0">
              <div className="d-flex align-items-center">
                <label className="form-label semibold-14 text-secondary">
                  Descrição
                </label>
              </div>

              <InputGroup Icon={LuClipboardEdit}>
                <Input
                  control={control}
                  errors={errors}
                  validation={{}}
                  name="description"
                  type="text"
                  id="description"
                  placeholder="Descrição"
                />
              </InputGroup>
            </div>
          </div>

          {watchRejectCalls && (
            <div className="col-lg-12 mt-3">
              <div className="d-flex align-items-center">
                <label className="form-label semibold-14 text-secondary">
                  Mensagem automática ao rejeitar chamadas
                </label>
              </div>

              <InputGroup Icon={LuClipboardEdit}>
                <Input
                  control={control}
                  errors={errors}
                  validation={{}}
                  name="rejectCallsMessage"
                  type="text"
                  id="rejectCallsMessage"
                  placeholder="Mensagem de rejeição"
                />
              </InputGroup>
            </div>
          )}

          <p className="mb-0 bold-20 mt-4">2. Configurações</p>
          <div className="row mt-3 d-flex align-items-center">
            <div className="col-lg-3">
              <div className="d-flex align-items-center">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input cursor-pointer"
                    style={{ width: "3.3em", height: "1.6em" }}
                    type="checkbox"
                    role="switch"
                    id="rejectCalls"
                    {...register("rejectCalls")}
                  />
                </div>
                <p className="mb-0 fw-semibold mx-2 text-secondary">
                  Rejeitar chamadas automático
                </p>
              </div>
            </div>

            <div className="col-lg-3 mt-2 mt-lg-0">
              <div className="d-flex align-items-center">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input cursor-pointer"
                    style={{ width: "3.3em", height: "1.6em" }}
                    type="checkbox"
                    role="switch"
                    id="alwaysOnLine"
                    {...register("alwaysOnLine")}
                  />
                </div>
                <p className="mb-0 fw-semibold mx-2 text-secondary">
                  Sempre online
                </p>
              </div>
            </div>

            <div className="col-lg-3 mt-2 mt-lg-0">
              <div className="d-flex align-items-center">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input cursor-pointer"
                    style={{ width: "3.3em", height: "1.6em" }}
                    type="checkbox"
                    role="switch"
                    id="readStatus"
                    {...register("readStatus")}
                  />
                </div>
                <p className="mb-0 fw-semibold mx-2 text-secondary">
                  Ler status
                </p>
              </div>
            </div>

            <div className="col-lg-3 mt-2 mt-lg-0">
              <div className="d-flex align-items-center">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input cursor-pointer"
                    style={{ width: "3.3em", height: "1.6em" }}
                    type="checkbox"
                    role="switch"
                    id="readMessages"
                    {...register("readMessages")}
                  />
                </div>
                <p className="mb-0 fw-semibold mx-2 text-secondary">
                  Ler mensagens automático
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column align-items-center justify-content-center mt-4">
          <button
            className="btn btn-success rounded-pill px-5 py-2 fw-semibold"
            disabled={loading}
          >
            {loading ? "Cadastrando..." : "Cadastrar"}
          </button>
          <Link
            to="/whatsapp/instances"
            className="mb-0 text-decoration-underline mt-2 text-secondary"
          >
            Cancelar
          </Link>
        </div>
      </form>
    </MainLayout>
  );
}

import WhatsAppConnectionStatus from "../enums/whatsapp-connection-status";

export function ConvertStatusToString(status: WhatsAppConnectionStatus) {
  switch (status) {
    case WhatsAppConnectionStatus.Connected:
      return "Conectado";
    case WhatsAppConnectionStatus.Disconnected:
      return "Desconectado";
    case WhatsAppConnectionStatus.WaitingForConnection:
      return "Aguardando conexão";

    default:
      return "Status desconhecido";
  }
}

import { FaCalendarAlt, FaHome, FaPhone, FaWhatsapp } from "react-icons/fa";
import { GoBroadcast } from "react-icons/go";
import { FaGear, FaPerson } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";

const links = [
  {
    path: "/",
    icon: <FaHome />,
    label: "Home",
  },
  {
    icon: <GoBroadcast />,
    label: "Conexões",
    submenu: [
      { path: "/whatsapp/instances", icon: <FaWhatsapp />, label: "WhatsApp" },
    ],
  },
  {
    path: "/profile",
    icon: <FaPerson />,
    label: "Minha conta",
  },
  // {
  //   path: "/schedules",
  //   icon: <FaCalendarAlt />,
  //   label: "Agendamentos",
  // },
];

export const NavLinks = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="d-flex mt-3 flex-column">
      {links.map((link, index) =>
        link.submenu ? (
          <div key={index} className="mb-3 accordion">
            <button
              className={`btn text-white d-flex align-items-center w-100 accordion-button p-0`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#submenu-${index}`}
              aria-expanded="true"
              aria-controls={`submenu-${index}`}
            >
              <div
                className="d-flex align-items-center justify-content-start"
                style={{ width: "40px" }}
              >
                {link.icon}
              </div>
              <span className="text-white medium-14">{link.label}</span>
            </button>
            <div id={`submenu-${index}`} className={`collapse show`}>
              <div className="mx-4 mt-3">
                {link.submenu.map((sub, subIndex) => (
                  <Link
                    key={subIndex}
                    to={sub.path}
                    className="text-decoration-none text-white w-100 d-flex align-items-center"
                  >
                    <div className="d-flex align-items-center justify-content-start">
                      {sub.icon}
                    </div>
                    <span className="text-white medium-14 mx-3">
                      {sub.label}
                    </span>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <Link
            key={index}
            to={link.path}
            className="text-decoration-none text-white w-100 d-flex align-items-center mb-3"
          >
            <div
              className="d-flex align-items-center justify-content-start"
              style={{ width: "40px" }}
            >
              {link.icon}
            </div>
            <span className="text-white medium-14">{link.label}</span>
          </Link>
        )
      )}
    </div>
  );
};
